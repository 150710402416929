.container  {
    overflow: hidden;
}
.header-section.fixed-header .header_padding  {
    padding: 10px 0;
}
.header-section.fixed-header .logo a img  {
    width: 150px;
}
.header-section.fixed-header .all_button button  {
    font-size: 14px;
    padding: 10px 18px;
}

.section_three .sec_inner_port_holder .slick-slide.slick-active  {
    z-index: 1;
}
.trusted-section .slick-slide,
.Customer_slider .slick-slide {
    padding:0 3px;
}
.Technologies .Technologies_heading:before {
    background-color: hsla(0,0%,100%,.4);
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 38%;
}
.get_experts .get_expert_arrow  li p {
    background: url(./Assets/Images/right1.png) no-repeat 0 7px;
}
.why_redensoft .why_redensoft_box h3:after {
    background: url(./Assets/Images/right2.png) no-repeat 0 0;
    background-size: 1.3125rem;
    content: "";
    height: 32px;
    left: 0;
    position: absolute;
    top: 6px;
    width: 32px;
}
.why_redensoft .why_redensoft_box ul li::after  {
    background-color: #484848;
    border-radius: 100px;
    content: "";
    height: 8px;
    left: 0;
    position: absolute;
    top: 13px;
    width: 8px;
}
.Customer_slider .video_box iframe  {
    width: 100%;
}
.inspire_slider .inspire_box  {
    margin: 0 10px 1px;
}
.acc_divul li {
    background: url(./Assets/Images/right3.svg) no-repeat 0 7px;
}
.contact_section .left ul li:before {
    background-image: url(./Assets/Images/right4.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    display: block;
    height: 14px;
    margin-right: 8px;
    position: relative;
    top: 6px;
    width: 14px;
}
.section_three .slick-track ,
.inspire_slider .slick-track {
    display: flex;
}
.section_three .slick-slide,  
.inspire_slider .slick-slide  {
    height: auto;
}
.section_three .blue_bar .slick-slide div ,
.inspire_slider .slick-slide div  {
    height: 100%;
}
.section_three.left,
.section_three .right{
    flex: 0 0 50%;
    max-width: 50%;
}
.inspire_box .client_box .image  {
    flex-shrink: 0;
    height: 4rem !important;
}
.inspire_box .client_box .image img  {
    object-fit: cover;
    object-position: center;
}
.inspire_box > div {
    height: auto !important;
}
.whatsapp-btn{
    position: fixed;
    bottom: 30px;
    left: 25px;
    z-index: 99;
    background-color: transparent;
    border: 0;
    width: 63px;
    cursor: pointer;
    height: 64px;
}
.whatsapp-btn img{
    object-fit: cover;
}
.back-to-top-button {
    position: fixed;
    z-index: 99;
    right: 25px;
    bottom: 30px;
    background-color: #20c0a0;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-section.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #1b0334;
    z-index: 99;
    animation: headerslide 0.3s ease-in;
}
@keyframes headerslide  {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@media (max-width:1420px)  {
    .container  {
        padding: 0 3%;
    }
}

.moadal_index  {
    z-index: 999;
}

.phone_input .react-tel-input .form-control  {
    width: 100% !important;
    border: none;
    border-radius: 0;
}

.phone_input .react-tel-input .flag-dropdown  {
    border: none;
    background-color: #fff;
    border-bottom: 1px solid #000;
}

.phone_input .react-tel-input .form-control  {
    font-size: 13px;
    font-weight: 400;
    color: #757575;
    border-bottom: 1px solid #000;
}
